@charset "UTF-8";
html {
  font-family: sans-serif;
  text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
}

optgroup {
  font-weight: bold;
}

button,
input,
select {
  overflow: visible;
}

button,
input,
select,
textarea {
  margin: 0;
}

button,
select {
  text-transform: none;
}

[type=button],
[type=reset],
[type=submit],
button {
  cursor: pointer;
}

[disabled] {
  cursor: default;
}

[type=reset],
[type=submit],
button,
html [type=button] {
  appearance: button;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  appearance: field;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  appearance: none;
}

.control.qty {
  max-width: 100px;
  border-radius: 5px;
  font-weight: 700;
  position: relative;
}
.control.qty span, .control.qty input {
  display: inline-block;
  width: 33.3333333333%;
  margin: 0;
  font-size: 18px;
  float: left;
  text-align: center;
  height: 41px;
  padding: 7px 0;
  color: #68685F;
}
.control.qty span:hover {
  color: #C3C3BF;
}

input:focus, select:focus, textarea:focus {
  box-shadow: none !important;
}

/* Custom radio button*/
input[type=radio]:not([name*=ratings]) {
  position: absolute;
  top: -9999px;
  visibility: hidden;
}
input[type=radio]:not([name*=ratings]) + label {
  position: relative;
  padding-left: 25px;
}
input[type=radio]:not([name*=ratings]) + label:before {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  content: "";
  width: 18px;
  height: 18px;
  border: 2px solid #B5BE0E;
  border-radius: 50%;
}
input[type=radio]:not([name*=ratings]) + label:after {
  position: absolute;
  content: "";
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: #B5BE0E;
  left: 5px;
  top: 5px;
  transform: scale(0);
  transition: ease all 0.2s;
}
input[type=radio]:not([name*=ratings]):checked + label:after {
  transform: scale(1);
  transition: ease all 0.2s;
}

/* Custom Checkbox */
input[type=checkbox] {
  position: absolute;
  top: -9999px;
  visibility: hidden;
}
input[type=checkbox] + label {
  position: relative;
  padding-left: 25px;
}
input[type=checkbox] + label:before {
  position: absolute;
  display: inline-block;
  left: 0;
  top: 0;
  content: "";
  width: 15px;
  height: 15px;
  border: 2px solid #C3C3BF;
}
input[type=checkbox] + label:after {
  position: absolute;
  display: inline-block;
  left: 4px;
  top: -2px;
  opacity: 0;
  color: #fff;
  content: "✓";
}
input[type=checkbox]:checked + label:before {
  border-color: #B5BE0E;
  background-color: #B5BE0E;
  transition: background-color 0.25s ease;
}
input[type=checkbox]:checked + label:after {
  opacity: 1;
  transition: ease all 0.3s;
  transition-delay: 0.25s;
}

.fieldset .field .control._with-tooltip .field-tooltip {
  position: relative;
}
.fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-action:before {
  content: "";
  width: 14px;
  height: 14px;
  background: url("../images/icons/tooltip.svg") center center no-repeat;
  position: absolute;
  right: 0;
  top: -62px;
}
.fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content {
  position: relative;
  top: unset;
  left: unset;
  right: unset;
  width: 100%;
}
.fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content:after, .fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content:before {
  display: none;
}
@media (min-width: 1024px) {
  .fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content:after, .fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content:before {
    display: block;
    left: unset;
    right: -1px;
    top: -20px;
    transform: rotate(90deg);
  }
}
@media (min-width: 1024px) {
  .fieldset .field .control._with-tooltip .field-tooltip .field-tooltip-content:before {
    border-right-color: #999;
  }
}

.page-print .logo {
  float: none;
  display: block;
  text-align: left;
}

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important;
  }
  a,
a:visited {
    text-decoration: underline !important;
  }
  pre,
blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed;
  }
  .table-wrapper table td {
    width: auto;
  }
  .table-wrapper table > tfoot > tr:first-child {
    border-top: 1px solid #999;
  }
  .box,
tr,
img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 1cm;
  }
  .block-content,
p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }
  .block-content {
    page-break-before: avoid;
  }
  .block-title,
h2,
h3 {
    page-break-after: avoid;
  }
  .nav-toggle {
    display: none !important;
  }
  .sidebar,
.nav-sections,
.header.content > *[class],
.panel.wrapper > *[class],
.footer.content > *[class] {
    display: none;
  }
  .logo,
.footer .copyright {
    display: block !important;
    margin: 10px 0;
  }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto;
  }
  .column.main {
    width: 100% !important;
    float: none !important;
  }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right;
  }
  .footer.content {
    padding: 0;
  }
}